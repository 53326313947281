import Papa from 'papaparse';
import { HOURS_PER_DAY } from '~src/enums/general';

const colors = [
  'hsl(60, 10%, 70%)', // Light neutral
  'hsl(60, 10%, 35%)', // Medium neutral
  'hsl(60, 10%, 15%)', // Dark neutral
  'hsl(61, 33%, 60%)', // Primary
];

export const getLineColor = (index) => {
  if (index < colors.length) return colors[index];

  const hue = Math.floor(index / 3) * 60;
  const lightness = ((index + 1) % 3) * 15 + 35; // Lightness of 50, 65 or 35, in that order

  if (hue <= 360) {
    return `hsl(${hue}, 28%, ${lightness}%)`;
  }
  return `hsl(${hue - 30}, 28%, ${lightness}%)`;
};

const moveInArray = (arr, from, to) => {
  const item = arr.splice(from, 1);
  arr.splice(to, 0, item[0]);
};

export const generateLabel = (el) => (el === 'mean' ? `P(${el})` : `P${el}`);

export const generateHorizontalBartChartViewData = (
  data,
  selectedDataEntity,
) => {
  const result = Object.keys(data).map((el, i) => {
    return {
      label: generateLabel(el),
      data: data[el].map((item) =>
        selectedDataEntity === 0 ? item / 24 : item,
      ),
      borderColor: colors[i],
      backgroundColor: colors[i],
    };
  });

  moveInArray(result, 2, 1);
  return result;
};

const getScatterChartPoints = (data, selectedDataEntity) => {
  const result = [];
  const length =
    selectedDataEntity === 0 ? data?.time?.length : data?.costs?.length;
  for (let i = 0; i < length; i += 1) {
    result.push({
      x: selectedDataEntity === 0 ? data?.time[i] / 24 : data?.costs[i],
      y: data.depth[i],
      label: '',
    });
  }

  return result;
};

export const generateScatterChartViewData = (data, selectedDataEntity) => {
  if (data) {
    const result = Object.keys(data).map((el, i) => {
      return {
        label: generateLabel(el),
        data: getScatterChartPoints(data[el], selectedDataEntity),
        borderColor: colors[i],
        pointBackgroundColor: colors[i],
      };
    });

    moveInArray(result, 2, 1);
    return result;
  }
};

export const convertOperationDurations = (durations = {}) =>
  Object.keys(durations).reduce(
    (acc, el) => ({
      ...acc,
      [el]: durations[el]?.map((d) => d / HOURS_PER_DAY),
    }),
    {},
  );

export const convertOperationVsDepthDurations = (operationVsDepth = {}) => {
  const updadtedOperationVsDepth = {};
  for (const key in operationVsDepth) {
    if (operationVsDepth) {
      updadtedOperationVsDepth[key] = {
        ...operationVsDepth[key],
        time: operationVsDepth[key].time?.map((t) => t / HOURS_PER_DAY),
      };
    }
  }
  return updadtedOperationVsDepth;
};

export const getRowsData = (rows) => {
  return rows.map((row) => {
    return row.cells?.map((cell, idx) => {
      if (idx === 0) {
        return cell.value.props?.children;
      } else {
        return cell.value;
      }
    });
  });
};
export const unParse = (headers, rows, config) => {
  const { delimiter } = config;
  const fields = headers[0].cells.map((cell) => cell.value);
  const units = headers[1].cells.map(
    (cell) => cell.value?.props?.children || '',
  );
  const input = [fields, units, ...rows];
  return Papa.unparse(input, {
    quotes: false,
    header: true,
    delimiter,
  });
};

export const generateVerticalStackedBarChartViewData = (chartData, labels) => {
  return labels.map((label, index) => ({
    label,
    data: Object.values(chartData).map((data) => data[index]),
    borderColor: colors[index],
    backgroundColor: getLineColor(index),
  }));
};
export const shownCumulativeProbabilities = [
  0, 9, 19, 29, 39, 49, 59, 69, 79, 89, 98,
];
export const shownCumulativeProbabilitiesForChart = [
  0, 9, 19, 29, 39, 49, 59, 69, 79, 89, 98,
];

export const generateCumulativeChartData = (
  dataForChart = [],
  shownItems = [],
) => {
  if (shownItems.length > 0) {
    return dataForChart.reduce((res, element, i) => {
      if (shownItems.includes(i)) {
        res.push({ x: element, y: i + 1 });
      }

      return res;
    }, []);
  } else {
    return dataForChart.map((el, idx) => ({ x: el, y: idx + 1 }));
  }
};
