import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { isEmpty, sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
  Modal,
  Dialog,
  Button,
  Heading,
  Field,
  Spacer,
  InputGroup,
  InputGroupAddon,
  FormRow,
  HelpIcon,
} from '@oliasoft-open-source/react-ui-library';
import { initialActivity } from '~store/entities/user-settings/user-settings';
import { selectSectionsDataStructure } from '~src/store/entities/sections-data-structure/selector';
import translations from '~src/internationalisation/translation-map.json';
import { useForm } from 'react-hook-form';
import { getResolver } from '~src/validation/resolver';
import { activitiesSchemaValidator } from '~schemas/ajv-validators';
import {
  Input,
  NumberInput,
  Select,
  TextArea,
  UnitInput,
  Toggle,
  InputWithVariable,
} from '~common/form-inputs';
import { estimateTypes, distributionTypes } from '~src/enums/tasks';
import {
  convertInputUnits,
  convertToInputUnits,
  convertToStorageUnits,
} from '~common/units/units';
import { BranchesTable } from './branches-table';

const ActivityModal = ({
  activity,
  operation,
  unitSettings,
  closeActivitiesForm,
  estimateTypesList,
  updateActivity,
  settings,
  isAdding,
  distributionTypesList,
  activities,
  activeOperation,
}) => {
  const { t } = useTranslation();
  const { company: companyId } = useParams();
  const isRisk = Boolean(activity?.parentId);

  const hasSubActivities = operation.activities?.some(
    (a) => a.parentId === activity.id && !a.isBranch,
  );

  const activeActivities = activities?.filter(
    (item) => item.sectionsOperationId === activeOperation?.sectionsOperationId,
  );
  const activityOptions = activeActivities.map((activity) => ({
    label: activity.name,
    value: activity.sectionsOperationActivityId,
  }));

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    clearErrors,
    trigger,
    setValue,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: false,
    defaultValues: initialActivity,
    resolver: getResolver(activitiesSchemaValidator),
  });

  const [isEditing, setIsEditing] = useState(true);

  const [
    estimateType,
    distributionType,
    min,
    most,
    max,
    isApplyFromOffset,
    sectionsOperationActivityId,
  ] = watch([
    'estimateType',
    'distribution',
    'min',
    'most',
    'max',
    'isApplyFromOffset',
    'sectionsOperationActivityId',
  ]);
  const isOperationSpeed = estimateType === estimateTypes.OPERATION_SPEED;

  useEffect(() => {
    const activityData = activity || initialActivity;
    const convertedLength = convertToInputUnits(
      activityData,
      ['from', 'to'],
      'm',
      unitSettings.length,
      true,
    );
    const convertedActivity = convertInputUnits(
      convertedLength,
      ['min', 'most', 'max'],
      isOperationSpeed ? 'm' : 'h',
      isOperationSpeed ? unitSettings.length : unitSettings.time,
      true,
    );
    reset(convertedActivity);
  }, [activity, reset]);

  const { withBranch } = watch();

  useEffect(() => {
    if (withBranch) {
      clearErrors(['min', 'most', 'max', '']);
    }
  }, [withBranch, handleSubmit]);

  const [branches, setBranches] = useState(
    operation.activities.filter(
      (a) => a.parentId === activity.id && a.isBranch,
    ),
  );

  const onSubmit = handleSubmit((data) => {
    const convertedLength = convertToStorageUnits(data, ['from', 'to'], {
      from: 'm',
      to: 'm',
    });
    const convertedActivity = convertInputUnits(
      convertedLength,
      ['min', 'most', 'max'],
      isOperationSpeed ? unitSettings.length : unitSettings.time,
      isOperationSpeed ? 'm' : 'h',
    );
    updateActivity(convertedActivity, branches, settings, companyId);
  });
  const onClose = () => closeActivitiesForm();

  useEffect(() => {
    trigger();
  }, [min, most, max]);

  const timeUnits = isOperationSpeed ? `${unitSettings.length}/hr` : 'hr';

  const distributionFieldMap = {
    [distributionTypes.PERT]: [
      { label: t(translations.minimum), name: 'min' },
      { label: t(translations.mostLikely), name: 'most' },
      { label: t(translations.maximum), name: 'max' },
    ],
    [distributionTypes.UNIFORM]: [
      { label: t(translations.minimum), name: 'min' },
      { label: t(translations.maximum), name: 'max' },
    ],
    [distributionTypes.SPIKE]: [
      { label: t(translations.mostLikely), name: 'most' },
    ],
  };

  const [branchesErros, setBranchesErros] = useState([]);
  const resolver = getResolver(activitiesSchemaValidator);

  const validator = async (branchesData) => {
    const certaintySum = sumBy(branchesData, 'certainty');
    const resolvedBranches = branchesData.map((branch) =>
      resolver({ ...branch, distribution: distributionType, estimateType }),
    );
    Promise.all(resolvedBranches)
      .then((res) => {
        if (certaintySum === 100) {
          return res.map((el) => el.errors);
        } else {
          return res.map((el) => ({
            ...el.errors,
            certainty: t(translations.totalValueOfProbabilityShouldBe, {
              sum: 100,
            }),
          }));
        }
      })
      .then((res) => {
        setBranchesErros(res);
      });
  };
  useEffect(() => {
    validator(branches);
  }, [branches, distributionType, estimateType]);

  const isBranchesValid = branchesErros.every((branch) => isEmpty(branch));
  const hasErrors = !(isEmpty(errors) && isBranchesValid);

  useEffect(() => {
    if (sectionsOperationActivityId && !isEditing) {
      const selectedOption = activityOptions.find(
        (option) => option.value === sectionsOperationActivityId,
      );
      setValue('name', selectedOption.label);
      setIsEditing(true);
    }
  }, [sectionsOperationActivityId]);

  const handleCategoryChange = (ev) => {
    setIsEditing(false);
    setValue('sectionsOperationActivityId', ev.target.value);
  };

  return (
    <Modal visible centered fullScreen>
      <Dialog
        dialog={{
          heading: isRisk
            ? t(translations.activityModel_newRisk)
            : t(translations.activityModel_newActivity),
          content: (
            <>
              <form>
                <InputGroup>
                  <Field label={t(translations.activity)}>
                    <Select
                      name="sectionsOperationActivityId"
                      options={activityOptions}
                      control={control}
                      errors={errors}
                      width="175px"
                      onChange={handleCategoryChange}
                      placeholder={t(translations.activityModel_selectActivity)}
                    />
                  </Field>
                  <Spacer width="var(--padding-sm)" />
                  <Field label={t(translations.name)}>
                    <Input
                      name="name"
                      control={control}
                      errors={errors}
                      width="175px"
                    />
                  </Field>
                </InputGroup>
                <InputGroup>
                  <Field label={t(translations.probability)}>
                    <InputGroup width={175}>
                      <NumberInput
                        name="certainty"
                        control={control}
                        errors={errors}
                      />
                      <InputGroupAddon>%</InputGroupAddon>
                    </InputGroup>
                  </Field>
                </InputGroup>
                <InputGroup>
                  <Field label={t(translations.estimateType)}>
                    <Select
                      name="estimateType"
                      control={control}
                      errors={errors}
                      options={estimateTypesList}
                      width="175px"
                      disabled={isApplyFromOffset}
                    />
                  </Field>
                  <Spacer width="var(--padding-sm)" />
                  <Field label={t(translations.activityModel_distributionType)}>
                    <Select
                      name="distribution"
                      control={control}
                      errors={errors}
                      options={distributionTypesList}
                      width="auto"
                      disabled={isApplyFromOffset}
                    />
                  </Field>
                </InputGroup>
                {isRisk && !hasSubActivities && (
                  <Toggle
                    name="withBranch"
                    label="Branch risk"
                    control={control}
                    noMargin
                  />
                )}
                {withBranch ? (
                  <>
                    <BranchesTable
                      branches={branches}
                      setBranches={setBranches}
                      branchesErros={branchesErros}
                      riskId={activity.id}
                      estimateType={estimateType}
                      distributionType={distributionType}
                    />
                    <Spacer />
                  </>
                ) : (
                  <>
                    {estimateType === estimateTypes.OPERATION_SPEED && (
                      <>
                        <Heading>{t(translations.depth)}</Heading>
                        <Spacer />
                        <InputGroup>
                          <Field label={t(translations.from)}>
                            <InputWithVariable
                              name="from"
                              control={control}
                              unitkey={'length'}
                              unit={unitSettings?.length}
                              errors={errors}
                            />
                          </Field>
                          <Spacer width="var(--padding-sm)" />
                          <Field label={t(translations.to)}>
                            <InputWithVariable
                              name="to"
                              control={control}
                              unitkey={'length'}
                              unit={unitSettings?.length}
                              errors={errors}
                            />
                          </Field>
                        </InputGroup>
                      </>
                    )}
                    <Heading>
                      {estimateType === estimateTypes.OPERATION_SPEED
                        ? t(translations.activityModel_operationSpeed)
                        : t(translations.time)}
                    </Heading>
                    <Spacer />
                    <FormRow>
                      {distributionFieldMap[distributionType]?.map((field) => (
                        <Field label={field.label} key={field.name}>
                          <UnitInput
                            name={field.name}
                            unit={timeUnits}
                            control={control}
                            errors={errors}
                            width="120px"
                            disabled={isApplyFromOffset}
                          />
                        </Field>
                      ))}
                    </FormRow>
                    <Field>
                      <Toggle
                        name="isApplyFromOffset"
                        label={t(
                          translations.activityModel_applyAllTimesFromOffsetWells,
                        )}
                        control={control}
                      />
                    </Field>
                  </>
                )}
                <Field label={t(translations.comments)}>
                  <TextArea
                    name="comments"
                    control={control}
                    errors={errors}
                    rows={5}
                  />
                </Field>
              </form>
            </>
          ),
          footer: (
            <>
              <Button
                label={t(translations.save)}
                colored
                onClick={onSubmit}
                disabled={isAdding || hasErrors}
              />
              <Button label={t(translations.cancel)} onClick={onClose} />
            </>
          ),
          onClose,
        }}
      />
    </Modal>
  );
};

const mapStateToProps = ({ entities }) => {
  const { activities } = selectSectionsDataStructure(entities);
  const estimateTypesList = [
    { label: 'Operation time', value: estimateTypes.OPERATION_TIME },
    { label: 'Operation speed', value: estimateTypes.OPERATION_SPEED },
  ];

  return {
    estimateTypesList,
    activities,
    distributionTypesList: [
      {
        label: 'Spike',
        value: distributionTypes.SPIKE,
        icon: (
          <HelpIcon
            text={i18n.t(
              translations.activityModel_spikeDistributionExplanation,
            )}
          />
        ),
      },
      {
        label: 'Uniform',
        value: distributionTypes.UNIFORM,
        icon: (
          <HelpIcon
            text={i18n.t(
              translations.activityModel_uniformDistributionExplanation,
            )}
          />
        ),
      },
      {
        label: 'Pert',
        value: distributionTypes.PERT,
        icon: (
          <HelpIcon
            text={i18n.t(
              translations.activityModel_pertDistributionExplanation,
            )}
          />
        ),
      },
    ],
  };
};

const Container = connect(mapStateToProps)(ActivityModal);

export { Container as ActivityModal };
