import React, { useEffect, useRef } from 'react';
import i18n from 'i18next';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  Field,
  InputGroup,
  Spacer,
  InputGroupAddon,
} from '@oliasoft-open-source/react-ui-library';
import {
  initialTemplate,
  wellConfigurations,
  drillingUnits,
} from '~store/entities/user-settings/user-settings';
import { getResolver } from '~src/validation/resolver';
import translations from '~src/internationalisation/translation-map.json';
import { highLevelInputsSchemaValidator } from '~schemas/ajv-validators';
import { Input, NumberInput, Select } from '~common/form-inputs';
import { useAutoSave } from '~common/auto-save/use-auto-save';
import { autoSaveWait } from '~src/config/config';
import {
  depthInequality,
  drillingUnit,
  wellConfiguration,
} from '~src/enums/settings';

const HighLevelForm = ({
  wellConfigurationOptions,
  drillingUnitOptions,
  depthOptions,
  settings,
  activeTemplate,
  updateTemplate,
}) => {
  const activeIndex = settings.templates.findIndex((item) => item.active);

  const { t } = useTranslation();
  const { company: companyId } = useParams();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: initialTemplate.highLevel,
    resolver: getResolver(highLevelInputsSchemaValidator),
  });

  useEffect(() => {
    reset(activeTemplate || initialTemplate.highLevel);
  }, [activeTemplate, reset]);

  const debounceUpdateTemplate = useRef(debounce(updateTemplate, autoSaveWait));
  const onSubmit = handleSubmit((data) => {
    debounceUpdateTemplate.current(activeIndex, settings, data, companyId);
  });

  useAutoSave(onSubmit, watch);
  const wellConfigurationValue = watch('wellConfiguration');

  return (
    <form>
      <Field label={t(translations.settings_rulesetName)}>
        <Input name="name" control={control} errors={errors} width={300} />
      </Field>
      <InputGroup>
        <Field label={t(translations.settings_wellConfiguration)}>
          <Select
            name="wellConfiguration"
            control={control}
            errors={errors}
            options={wellConfigurationOptions}
            width="110px"
            onChange={(e) =>
              setValue(
                'drillingUnit',
                e.target.value === wellConfiguration.ONSHORE
                  ? drillingUnit.LAND_RIG
                  : drillingUnit.SEMI_SUBMERSIBLE,
              )
            }
          />
        </Field>
        <Spacer width="var(--padding-sm)" />
        <Field label={t(translations.settings_drillingUnit)}>
          <Select
            name="drillingUnit"
            control={control}
            errors={errors}
            options={drillingUnitOptions.filter((item) =>
              wellConfigurationValue === wellConfiguration.ONSHORE
                ? item.value === drillingUnit.LAND_RIG
                : item.value !== drillingUnit.LAND_RIG,
            )}
            width="170px"
          />
        </Field>
      </InputGroup>
      <InputGroup>
        <Field label={t(translations.settings_waterDepth)}>
          <InputGroup width="300px">
            <Select
              name="waterDepthInequality"
              control={control}
              errors={errors}
              options={depthOptions}
              width={170}
            />
            <NumberInput name="waterDepth" control={control} errors={errors} />
            <InputGroupAddon>m</InputGroupAddon>
          </InputGroup>
        </Field>
        <Spacer width="var(--padding-sm)" />
        <Field label={t(translations.settings_wellheadDepth)}>
          <InputGroup width="300px">
            <Select
              name="wellheadDepthInequality"
              control={control}
              errors={errors}
              options={depthOptions}
              width={170}
            />
            <NumberInput
              name="wellheadDepth"
              control={control}
              errors={errors}
            />
            <InputGroupAddon>m</InputGroupAddon>
          </InputGroup>
        </Field>
      </InputGroup>
    </form>
  );
};

const mapStateToProps = () => {
  const wellConfigurationOptions = wellConfigurations.map((item) => ({
    ...item,
    label: i18n.t(item.label),
  }));
  const drillingUnitOptions = drillingUnits.map((item) => ({
    ...item,
    label: i18n.t(item.label),
  }));

  const depthOptions = [
    {
      label: i18n.t(translations.settings_lessOrEqualTo),
      value: depthInequality.LESS_OR_EQUAL,
    },
    {
      label: i18n.t(translations.settings_moreThan),
      value: depthInequality.MORE_THAN,
    },
  ];

  return {
    wellConfigurationOptions,
    drillingUnitOptions,
    depthOptions,
  };
};

const Container = connect(mapStateToProps)(HighLevelForm);

export { Container as HighLevelForm };
