import { IPriceBookItem } from "~src/common/interfaces/price-book.interfaces";
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import { CostType, QuantityType } from "~src/enums/cost-setup";

export const headings = ['name', 'vendor', 'price', 'currency', 'priceType'];

const headerTranslationsMap: { [key: string]: string } = {
  name: i18n.t(translations.name),
  vendor: i18n.t(translations.priceBook_vendor),
  price: i18n.t(translations.priceBook_price),
  currency: i18n.t(translations.currency),
  priceType: i18n.t(translations.priceBook_priceType),
};

export   const typeList = [
  { label: i18n.t(translations.costSetup_dayRate), value: CostType.DayRate },
  { label: i18n.t(translations.costSetup_lumpSum), value: CostType.LumpSum },
  {
    label: `${i18n.t(translations.costSetup_quantity)}: ${i18n.t(
      translations.costSetup_length,
    )}`,
    value: `${CostType.Quantity}-${QuantityType.Length}`,
  },
  {
    label: `${i18n.t(translations.costSetup_quantity)}: ${i18n.t(
      translations.costSetup_volume,
    )}`,
    value: `${CostType.Quantity}-${QuantityType.Volume}`,
  },
];
export const filterAndSortDataRows = (
  dataRows: IPriceBookItem[],
  filters: IPriceBookItem | Record<string, never>,
  sorts: { [key in keyof IPriceBookItem]?: string },
) =>
  dataRows
    .filter((row) =>
      (Object.keys(filters) as (keyof typeof filters)[]).every((key) => {
        return filters[key] === ''
          ? true
          : row[key]
              ?.toString()
              .toLowerCase()
              .includes((filters[key] || '').toString().toLowerCase());
      }),
    )
    .sort((a, b) => {
      for (const [key, value] of Object.entries(sorts)) {
        const keyTyped = key as keyof IPriceBookItem;
        switch (value) {
          case 'up': {
            return typeof a[keyTyped] === 'string' &&
              typeof b[keyTyped] === 'string'
              ? (a[keyTyped] as string)?.localeCompare(b[keyTyped] as string)
              : (a[keyTyped] as number) - (b[keyTyped] as number);
          }
          case 'down': {
            return typeof a[keyTyped] === 'string' &&
              typeof b[keyTyped] === 'string'
              ? (b[keyTyped] as string)?.localeCompare(a[keyTyped] as string)
              : (b[keyTyped] as number) - (a[keyTyped] as number);
          }
          default:
            return 0;
        }
      }
      return 0;
    });

export const dataHeaders = (
  dataRowsKeys: string[],
  filters: IPriceBookItem | Record<string, never>,
  setFilters: (args: IPriceBookItem) => void,
  sorts: { [key in keyof IPriceBookItem]?: string },
  setSorts: (args: { [key in keyof IPriceBookItem]?: string }) => void,
) => {
  const dataSortCells = dataRowsKeys.map((key) => {
    const keyTyped = key as keyof IPriceBookItem;
    const sort = Object.keys(sorts).includes(key) ? sorts[keyTyped] : '';
    const prettifyHeaderValue = headerTranslationsMap[keyTyped];
    return {
      key,
      value: prettifyHeaderValue,
      hasSort: true,
      sort,
      onSort: () => {
        const newSort = sort === 'up' ? 'down' : 'up';
        setSorts({
          [keyTyped]: newSort,
        });
      },
    };
  });
  const dataFilterCells = dataRowsKeys.map((key) => {
    const keyTyped = key as keyof IPriceBookItem;
    const filterValue = Object.keys(filters).includes(key)
      ? filters[keyTyped]
      : '';
    return {
      key,
      value: filterValue,
      type: 'Input',
      placeholder: 'Filter',
      onChange: (ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
        setFilters({
          ...filters,
          [keyTyped]: ev.target.value,
        } as IPriceBookItem),
    };
  });
  return {
    dataSortCells,
    dataFilterCells,
  };
};